.auth-page-main {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-page-content {
  height: 300px;
  width: 500px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 20px;
}
