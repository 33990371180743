.header-main {
  height: 80px;
  width: 100%;
  background-color: #ebeff2;
  display: flex;
  justify-content: center;
}

.header-content {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
