.direction-content {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.direction-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

/* Обрезаем текст в выбранном элементе Select */
.ant-select-selection-search {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* или задайте фиксированную ширину */
}
