.calc-container-main {
  display: flex;
  height: calc(100vh - 120px);
  width: 50%;
  background-color: #ebeff2;
  border-radius: 10px;
  overflow-y: scroll;
  padding: 10px;
}

.calc-container-main::-webkit-scrollbar {
  width: 10px;
}

.calc-container-main::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 20px;
}

.calc-container-main::-webkit-scrollbar-track {
  background: transparent;
}

.calc-container-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 10px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff4d4f !important;
}

.ant-tabs-ink-bar {
  background-color: #ff4d4f !important;
}

.ant-tabs-tab-btn:hover {
  color: #ff4d4f;
}

/* .ant-tabs-nav-wrap {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.ant-tabs-nav::before {
  display: none;
} */

.tab-keys-text {
  font-size: 18px;
  font-weight: bold;
}
