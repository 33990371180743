.main-page-main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-image: url("../../../public/bus.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-page-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: end;
  width: 100%;
  height: calc(100vh - 100px);
  margin: 20px;
}

.part-main {
  display: flex;
  height: fit-content;
  min-height: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  gap: 30px;
}

.part-content,
.part-content-info,
.part-content-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.part-content-info {
  width: 20%;
}

.part-content-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}

.part-content-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.cell {
  flex: 1 1 calc(50% - 5px);
  text-align: start;
  width: 100%;
}

.part-header-text {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.emty-description-text {
  display: flex;
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
}

.circle-block {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #ff4d4f;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
}
